/* Material Icons */
@import './material-icons.scss';

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/react/css/normalize.css';
@import '~@ionic/react/css/structure.css';
@import '~@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/react/css/padding.css';
@import '~@ionic/react/css/float-elements.css';
@import '~@ionic/react/css/text-alignment.css';
@import '~@ionic/react/css/text-transformation.css';
@import '~@ionic/react/css/flex-utils.css';
@import '~@ionic/react/css/display.css';

/* Ant Design 3.x */
@import '~antd/es/tabs/style/index.css';
@import '~antd/es/upload/style/index.css';
@import '~antd/es/select/style/index.css';
@import '~antd/es/button/style/index.css';
@import '~antd/es/menu/style/index.css';
@import '~antd/es/dropdown/style/index.css';
@import '~antd/es/spin/style/index.css';

:root {
  --ion-color-primary: #ffffff;
  --ion-color-primary-rgb: 255, 255, 255;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #e0e0e0;
  --ion-color-primary-tint: #ffffff;

  --ion-color-secondary: #1890ff;
  --ion-color-secondary-rgb: 24, 144, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #157fe0;
  --ion-color-secondary-tint: #2f9bff;

  --ion-color-tertiary: #aeaeae;
  --ion-color-tertiary-rgb: 174, 174, 174;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #999999;
  --ion-color-tertiary-tint: #b6b6b6;

  --ion-color-transparent: rgba(0, 0, 0, 0);
  --ion-color-transparent-rgb: 0, 0, 0, 0;
  --ion-color-transparent-contrast: #ffffff;
  --ion-color-transparent-contrast-rgb: 255, 255, 255;
  --ion-color-transparent-shade: rgba(0, 0, 0, 0);
  --ion-color-transparent-tint: rgba(0, 0, 0, 0.1);

  --ion-color-black: #000000;
  --ion-color-black-rgb: 0, 0, 0;
  --ion-color-black-contrast: #ffffff;
  --ion-color-black-contrast-rgb: 255, 255, 255;
  --ion-color-black-shade: #000000;
  --ion-color-black-tint: #1a1a1a;

  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0, 0, 0;
  --ion-color-white-shade: #f0f0f0;
  --ion-color-white-tint: #ffffff;

  --ion-color-success: #339900;
  --ion-color-success-rgb: 51, 153, 0;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #2d8700;
  --ion-color-success-tint: #47a31a;

  --ion-color-warning: #ffa500;
  --ion-color-warning-rgb: 255, 165, 0;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e09100;
  --ion-color-warning-tint: #ffae1a;

  --ion-color-danger: #ff0000;
  --ion-color-danger-rgb: 255, 0, 0;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #e00000;
  --ion-color-danger-tint: #ff1a1a;

  --ion-color-dark: #404040;
  --ion-color-dark-rgb: 64, 64, 64;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #383838;
  --ion-color-dark-tint: #535353;

  --ion-color-medium: #aeaeae;
  --ion-color-medium-rgb: 174, 174, 174;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #999999;
  --ion-color-medium-tint: #b6b6b6;

  --ion-color-light: #bcbcbc;
  --ion-color-light-rgb: 188, 188, 188;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #a5a5a5;
  --ion-color-light-tint: #c3c3c3;

  --ion-background-color: transparent;
  --ion-background-color-rgba: 0, 0, 0, 0;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-color-step-50: #0d0d0d;
  --ion-color-step-100: #1a1a1a;
  --ion-color-step-150: #262626;
  --ion-color-step-200: #000000;
  --ion-color-step-250: #404040;
  --ion-color-step-300: #4d4d4d;
  --ion-color-step-350: #595959;
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;

  --ion-toolbar-background: rgba(0, 0, 0, 0);
  --ion-item-background: rgba(0, 0, 0, 0);
  --ion-card-background: rgba(0, 0, 0, 0);
  --ion-backdrop-color: var(--ion-color-white-shade);
  --ion-backdrop-opacity: 0.4;
  --ion-border-color: var(--ion-color-step-250, #c8c7cc);
  --ion-item-border-color: var(--ion-border-color);
  --ion-internal-light-dark: #142a4e;

  --tofino-font-size-regular: 16px;
  --tofino-font-size-small: 13px;
  --tofino-font-size-smallest: 11px;
}

body {
  background-color: var(--ion-internal-light-dark);
  font-size: var(--tofino-font-size-regular);
}

p {
  line-height: 1.3em;
}

small {
  font-size: var(--tofino-font-size-small);
}

dl {
  margin: 0;
  padding: 0;

  dt,
  dd {
    margin: 0;
    padding: 0;
  }

  &.tofino-tabular-list {
    display: flex;
    flex-wrap: wrap;

    dt,
    dd {
      width: 50%;
      margin-bottom: 4px;
      font-size: var(--tofino-font-size-regular);
    }

    dd {
      text-align: right;
    }
  }

  &.tofino-stacked-list {
    dt {
      font-size: var(--tofino-font-size-small);
      opacity: 0.8;
    }

    dd {
      font-size: var(--tofino-font-size-regular);
      margin-bottom: 8px;
    }
  }
}

input {
  &::-webkit-contacts-auto-fill-button,
  &::-webkit-credentials-auto-fill-button {
    visibility: hidden;
    pointer-events: none;
    position: absolute;
    right: 0;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

ion-item {
  font-size: var(--tofino-font-size-regular) !important;

  --detail-icon-color: var(--ion-color-white-shade);
  --detail-icon-opacity: 1;

  input,
  textarea,
  ion-input {
    font-size: var(--tofino-font-size-regular) !important;

    &[disabled='true'] {
      color: var(--ion-color-medium) !important;
    }
  }

  &.item-disabled {
    opacity: 0.7 !important;
  }

  &.item-interactive-disabled {
    * {
      filter: grayscale(1);
      opacity: 1 !important;
      pointer-events: auto !important;
      color: var(--ion-color-medium) !important;
    }

    ion-button,
    ion-toggle {
      pointer-events: none !important;

      * {
        pointer-events: none !important;
      }
    }

    input,
    textarea,
    ion-input {
      color: var(--ion-color-medium) !important;
    }
  }

  .tofino-placeholder {
    color: var(--ion-color-medium) !important;
  }

  &.item-label-stacked {
    ion-label {
      font-size: var(--tofino-font-size-small);
      color: var(--ion-color-medium) !important;
      margin-bottom: 0 !important;
    }

    ion-input {
      --padding-top: 0 !important;

      input {
        transform: none !important;

        &.cloned-input {
          opacity: 0 !important;
        }
      }
    }
  }

  .label-stacked.sc-ion-label-ios-h {
    font-size: var(--tofino-font-size-small) !important;
  }

  &.tofino-stacked-item {
    ion-label {
      margin-top: 6px;
      margin-bottom: 14px;
      line-height: 21px;

      small {
        font-size: var(--tofino-font-size-small);
        line-height: 27px;
        vertical-align: middle;
      }
    }

    ion-input {
      max-width: calc(100% - 20px);
    }
  }

  &.tofino-required-item {
    ion-label::before {
      display: inline-block;
      margin-right: 4px;
      color: #f5222d;
      font-size: var(--tofino-font-size-small);
      content: '*';
    }

    &.item-interactive-disabled {
      ion-label::before {
        display: none;
      }
    }
  }

  &.tofino-disabled-item {
    ion-input {
      input {
        color: var(--ion-color-medium) !important;
      }
    }
  }

  &.tofino-error-item {
    ion-icon,
    ion-text,
    ion-label,
    ion-inner,
    ion-input,
    ion-datetime,
    .material-icons {
      color: var(--ion-color-danger) !important;
      --color: var(--ion-color-danger) !important;
    }

    .tofino-placeholder {
      color: var(--ion-color-danger) !important;
    }

    &::part(detail-icon) {
      color: var(--ion-color-danger) !important;
    }
  }
}

ion-button {
  margin: 0;
  color: white;

  &[color='transparent'] {
    border: 1px solid var(--ion-item-border-color);
  }
}

ion-tab-bar {
  ion-tab-button {
    ion-label {
      font-size: var(--tofino-font-size-smallest);
      white-space: nowrap;
    }
  }
}

ion-row {
  ion-col {
    padding: 0;
  }
}

ion-segment {
  ion-segment-button {
    ion-label {
      text-transform: none;
    }
  }
}

ion-alert {
  ion-backdrop {
    background-color: transparent !important;
  }

  .alert-wrapper {
    background: var(--ion-color-white-shade) !important;

    button {
      .alert-button-inner {
        color: var(--ion-color-secondary) !important;
      }

      &.alert-button-role-cancel {
        .alert-button-inner {
          color: var(--ion-color-black-tint) !important;
        }
      }
    }

    * {
      border-color: var(--ion-color-light) !important;
      color: var(--ion-color-black-tint) !important;
    }

    .alert-head {
      .alert-title {
        font-size: var(--tofino-font-size-regular);
      }
    }
  }

  input,
  textarea {
    background-color: unset !important;
  }

  .alert-message.sc-ion-alert-ios:empty {
    padding-bottom: 0 !important;
  }

  &.tofino-required-alert {
    .alert-title::before {
      display: inline-block;
      margin-right: 4px;
      color: #f5222d;
      content: '*' !important;
    }
  }
}

ion-loading {
  .loading-wrapper {
    overflow: hidden;
    min-width: 280px !important;
    padding: 16px !important;
    justify-content: flex-start !important;
    background: var(--ion-color-white-shade) !important;
    color: var(--ion-color-black-tint) !important;
  }

  .loading-spinner.sc-ion-loading-ios + .loading-content.sc-ion-loading-ios {
    margin-inline-start: 8px !important;
    white-space: nowrap;
  }
}

ion-popover {
  [popover]:not(:popover-open):not(dialog[open]) {
    display: contents;
  }

  ion-backdrop {
    background-color: transparent !important;
  }

  .popover-wrapper {
    .popover-arrow {
      &::after {
        background: var(--ion-color-white-shade);
      }
    }

    .popover-content {
      --width: 240px;
      background: var(--ion-color-white-shade);

      p,
      dl,
      ion-text:not([color]),
      ion-label:not([color]) {
        color: var(--ion-color-black-tint);
      }
    }
  }

  ion-item {
    --border-color: var(--ion-color-light);

    ion-text[slot='start'] {
      margin-right: 16px;
    }
  }
}

ion-toggle {
  height: 28px;
  width: 48px;
  --handle-width: 24px;
  --background: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.75);
  --background-checked: var(--ion-color-secondary);
  --handle-background: var(--ion-color-secondary-contrast);
  --handle-background-checked: var(--ion-color-secondary-contrast);
}

ion-searchbar {
  input {
    background-color: var(--ion-internal-light-dark) !important;
  }

  &.searchbar-disabled {
    opacity: 1 !important;

    input {
      color: var(--ion-color-light) !important;
    }
  }

  &.tofino-error-item {
    input {
      border: 1px solid var(--ion-color-danger) !important;
    }
  }
}

ion-modal {
  .modal-wrapper {
    @media screen and (orientation: portrait) {
      --height: 50%;
      --max-height: 50%;
    }
    position: absolute;
    bottom: 0;
  }
}

ion-picker {
  .picker-wrapper {
    background-image: url('./images/wallpaper.png') !important;

    .picker-above-highlight,
    .picker-below-highlight {
      opacity: 0.2;
    }

    .picker-toolbar {
      button {
        font-weight: normal !important;
        color: var(--ion-color-secondary);
      }
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.invisible {
  visibility: hidden;
}

.opacity-70 {
  opacity: 0.7;
}

.font-weight-500 {
  font-weight: 500;
}

.mb-0 {
  margin-bottom: 0;
}

.ml-neg-5 {
  margin-left: -5px;
}

.vertical-align-middle {
  vertical-align: middle;
}

.ion-page {
  background-image: url('./images/wallpaper.png');
}

.ant-tabs {
  font-size: unset;
  line-height: unset;
  color: var(--ion-color-primary);

  .ant-tabs-bar {
    font-size: var(--tofino-font-size-regular) !important;

    &.ant-tabs-top-bar {
      margin-bottom: 0;
      border-bottom-color: var(--ion-border-color);
    }
  }

  .ant-tabs-tab {
    margin-right: 8px;
  }

  .ant-tabs-tab-next,
  .ant-tabs-tab-prev {
    color: var(--ion-color-primary);

    &.ant-tabs-tab-btn-disabled {
      color: var(--ion-color-medium);
    }
  }

  .ant-tabs-nav .ant-tabs-tab-active {
    font-weight: unset;
  }

  .ant-tabs-nav .ant-tabs-tab-disabled,
  .ant-tabs-nav .ant-tabs-tab-disabled:hover {
    color: var(--ion-color-medium);
  }
}

.ant-select {
  width: 100%;
  font-size: var(--tofino-font-size-regular);
  border: none !important;
  outline: none !important;
  box-shadow: none !important;

  .ant-select-selection {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    background-color: var(--ion-internal-light-dark);
    border-radius: 8px;

    .ant-select-selection__rendered {
      color: var(--ion-color-primary);
    }
  }

  .ant-select-arrow {
    color: var(--ion-color-light);
  }
}

.ant-select-dropdown {
  background-color: var(--ion-internal-light-dark);

  .ant-select-dropdown-menu {
    margin-top: 0;
    border-radius: 5px;
    background-color: var(--ion-internal-light-dark);

    .ant-select-dropdown-menu-item,
    .ant-select-dropdown-menu-item-active {
      color: var(--ion-color-primary);
      background-color: unset;
    }
  }
}

.ant-dropdown {
  border-radius: 5px;
  background-color: var(--ion-internal-light-dark);

  .ant-dropdown-menu,
  .ant-dropdown-menu-active {
    color: var(--ion-color-primary);
    background-color: var(--ion-internal-light-dark);

    .ant-dropdown-menu-item-divider {
      background-color: var(--ion-border-color);
    }

    .ant-dropdown-menu-item,
    .ant-dropdown-menu-item-active {
      color: var(--ion-color-primary);
      background-color: var(--ion-internal-light-dark);
    }
  }
}
